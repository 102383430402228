import React from "react";
import PromoSection from "web/react/components/promo-section/promo-section";
import { AboutUsContentsSerializer } from "web/types/serializers";
import styles from "./about-us-contents.module.css";

function AboutUsContents({
    app_banners: appBanners,
    title,
    top_paragraphs: topParagraphs,
}: AboutUsContentsSerializer): React.ReactElement {
    return (
        <>
            <div className={styles.contents}>
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
                <div className={styles.contentsTop}>
                    {topParagraphs.map((p) => (
                        <p key={p} dangerouslySetInnerHTML={{ __html: p }} />
                    ))}
                </div>
            </div>
            {appBanners && appBanners.length && (
                <div className={styles.promoSectionWrapper}>
                    <PromoSection data={appBanners} />
                </div>
            )}
        </>
    );
}

export default AboutUsContents;
