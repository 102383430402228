import React from "react";
import AboutUsContents from "web/react/components/about-us/about-us-contents";
import AboutUsDiscoverMore from "web/react/components/about-us/about-us-discover-more";
import AboutUsNews from "web/react/components/about-us/about-us-news";
import AboutUsPressInquiries from "web/react/components/about-us/about-us-press-inquiries";
import withReduxProvider from "web/react/redux-provider";
import { AboutLayoutSerializer } from "web/types/serializers";
import styles from "./about-us.module.css";

interface AboutUsProps {
    layout_data: AboutLayoutSerializer;
}

function AboutUsPage({
    layout_data: { contents, press_inquiries: pressInquiries, discover_more: discoverMore, news },
}: AboutUsProps): React.ReactElement {
    return (
        <div className={styles.aboutUsWrapper}>
            {contents && <AboutUsContents {...contents} />}
            {discoverMore && <AboutUsDiscoverMore {...discoverMore} />}
            {news && (
                <>
                    <div className={styles.aboutUsDivider} />
                    <AboutUsNews {...news} />
                </>
            )}
            {pressInquiries && <AboutUsPressInquiries {...pressInquiries} />}
        </div>
    );
}

export default withReduxProvider(AboutUsPage);
